import Alpine from 'alpinejs'
import whenDomReady from "when-dom-ready"
import ticketBookingForm from './TicketBooking/TicketBooking'

declare global {
    interface Window {
        Alpine: typeof Alpine
        algoliaAppId: string
        algoliaSearchOnlyApiKey: string
    }
}
whenDomReady(async () => {
    window.Alpine = Alpine
    window.algoliaAppId = import.meta.env.VITE_ALGOLIA_APP_ID as string
    window.algoliaSearchOnlyApiKey = import.meta.env.VITE_ALGOLIA_SEARCH_ONLY_API_KEY as string
    ticketBookingForm()

    jQuery('#fluentform_1').on('fluentform_submission_success', function () {
        jQuery('.modal-scroll-box').removeClass('overflow-y-auto')
    })

    jQuery('.nav-dropdown').on('click', (el) => {
        // get data target of clicked element
        const button = jQuery(el.currentTarget)
        const target = button.data('target')
        const targetEl = jQuery(`.${ target }`)

        button.toggleClass('shown')
        targetEl.toggleClass('shown')
    })

    Alpine.start()
})

// Hot Module Logic
if ((import.meta as any).hot) {
    (import.meta as any).hot.on('vite:beforeFullReload', () => {
        throw '(skipping full reload)'
    })
}
